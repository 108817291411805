@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
  line-height: inherit !important;
  color: #181818;
}

label {
  font-size: 1.25rem !important;
  font-family: 'Roboto', sans-serif !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit !important;
  font-family: 'Roboto', sans-serif !important;
}

a,
button {
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;

}

a:hover {
  color: #AA3361;
  font-family: 'Roboto', sans-serif !important;
}

b,
strong {
  font-weight: bolder !important;
  -webkit-font-smoothing: antialiased !important;
  font-family: 'Roboto', sans-serif !important;
}

span {
  font-family: 'Roboto', sans-serif !important;
}

.swal2-icon.swal2-success {
  border-color: #a5dc8600 !important;
  color: #01a60100 !important;
}

.main-login-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-main-bg img {
  filter: invert();
}

.shadow-red {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}



.login-main-bg {
  background: #fff;
  border-radius: 10px;
}

.login .shadow-red {
  box-shadow: 0px 0px 40px -4px #be123c4d;
}

.theme-color {
  color: #181818;
  transition: 0.5s;

}

.lighblue-text {
  color: #AA3361;
  transition: 0.5s;
}

.lighblue-text:hover {
  color: #587181;
}

.theme-bg-color {
  background-color: #181818;
}

.theme-text-color {
  color: #181818;
  transition: 0.5s;
}

.theme-active-color {
  color: #AA3361;
}

.hover-active-color,
.theme-text-color.active,
.theme-text-color:hover {
  color: #AA3361;
  transition: 0.5s;
}

.menu-svg,
.menu-svg path .menu-svg polygon {
  transition: 0.5s;
  fill: #181818;
}

.theme-text-color:hover .menu-svg,
.theme-text-color:hover .menu-svg path,
.theme-text-color:hover .menu-svg polygon {
  fill: #AA3361;
  transition: 0.5s;
}

.theme-text-color:hover .menu-svg circle,
.theme-text-color.active .menu-svg circle {
  fill: #ffff;
  transition: 0.5s;
}

.theme-text-color.active .menu-svg path,
.theme-text-color.active .menu-svg polygon {
  fill: #AA3361;
  transition: 0.5s;
}

.menu-svg:hover path,
.menu-svg:hover polygon {
  fill: #AA3361;
  transition: 0.5s;
}

.menu-svg:hover .menu-svg circle,
.menu-svg.active .menu-svg circle {
  fill: #AA3361;
  transition: 0.5s;
}

.menu-svg.active .menu-svg path,
.menu-svg.active .menu-svg polygon {
  fill: #AA3361;
  transition: 0.5s;
}

.min-h-30 {
  min-height: 30px;
}

.max-w-70 {
  max-width: 70px;
}

.h-19 {
  height: 19px;
}

.z-ind-1 {
  z-index: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

/* Custom-Scrollbar Start */
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #AA3361;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #AA3361;
}

/* Custom-Scrollbar End */


/* Breadcome sec Start*/
.breadcome-sec {
  background: #AA3361;
}

.breadcome-sec .left-title-sec p {
  font-family: "'Roboto', sans-serif !important";
  font-size: 32px;
}

.breadcome-sec .right-links-sec li a {
  font-family: "'Roboto', sans-serif !important";
  font-size: 18px;
}

/* Breadcome sec End*/
/* Sidebar- Menu section Start */

.menu-icon-img .menu-svg {
  min-width: 25px;
}

.menu-icon-img p.menu-title {
  width: 85%;
  margin-top: -3px;
  white-space: break-spaces;
  font-size: 20px !important;
  font-family: "'Roboto', sans-serif !important";
}

.sub-menu-title {
  white-space: break-spaces;
  font-size: 20px;
}

.custom-make-menu {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.custom-menu-part {
  left: 35px;
}

/* Sidebar- Menu section End */

.main-body-section {
  padding: 35px;
  background: #f7f7f7;
}

.SectionWithBorder-bg {
  background: #e9e9e9;
}


.footer-bg {
  background: #181818;
}

/* Custom card Start */
.custom-card {
  background: #fff;
  padding: 50px 55px;
}

/* Custom card End */

/* Custom Accordian Start  */
.accordian-body {
  padding: 35px 35px 25px;
}

.accordion-main-box {
  background: #fff;
  margin-bottom: 10px;
  border: 1px solid #6b7280;
}

.accordion-main-box button {
  padding: 12px 25px;
  border-color: #6b7280;
}

.accordion-main-box button.text-blue-gray-700 {
  border: none;
}

.accordion-body-section {
  padding: 25px !important;
}

.dropdown-option-section-list button {
  padding: 3px 25px;
}

/* 
 .custom-accordian-overflow .overflow-hidden{
  overflow:initial !important;
}  */
.plush-btn {
  padding: 0 !important;
}

.signup-text-area {
  height: 100%;
}

.contribution-tble tr {
  background: transparent !important;
}



.accordion-main-box.with-sub-accordion .accordion-body-section {
  padding: inherit 15px !important;
}

.sub-accordian .accordian-header-btn {
  padding: 13px 10px;
  border: none;
}

.navigation-icon .plus-sign {
  opacity: 0;
  width: 0;
  display: inline-block;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
}

.navigation-icon .minus-sign {
  opacity: 1;
  width: auto;
  display: inline-block;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
}

.accordion-main-box button.text-blue-gray-700 .navigation-icon .plus-sign {
  opacity: 1;
  width: auto;
}

.accordion-main-box button.text-blue-gray-700 .navigation-icon .minus-sign {
  opacity: 0;
  width: 0;
}



.financial-record-sec .accordion-body-section {
  padding-top: 0 !important;
}

/* Custom Accordian End  */



/* Custom Radio Start */

.form-radio {
  color: #181818 !important;
  width: 25px !important;
  height: 25px !important;
  border-color: #181818 !important;
  padding: 3px !important;
}

.form-radio:focus {
  --tw-ring-color: #2563eb00 !important;
}

.form-radio:checked {
  background-color: #fff !important;
  padding: 10px !important;
  background-image: url(../public/circle-chekbox.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 21px 25px !important;
}

.form-radio.disabled-radio {
  background: #e9e9e9;
  cursor: not-allowed;
}

/* Custom Radio End*/
.small-title {
  font-size: 24px;
}

.font-bold {
  font-family: 'Roboto', sans-serif !important;
  font-weight: bold;
}

.theme-color {
  color: #181818;
  transition: 0.5s;
  font-family: 'Roboto', sans-serif !important;
  font-weight: normal;
}

/* Input start*/

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.css-6j8wv5-Input input,
.css-6j8wv5-Input input:after {
  border-color: none !important;
  outline: none !important;
  --tw-ring-color: none !important;
}

input,
.btn-dropdown,
select,
textarea {
  height: 52px;
  color: #181818;
  border-color: #181818 !important;
}

.login-input {
  height: auto;
}

.custom-input,
input {
  color: #181818 !important;
}

.custom-input input {
  height: 30px;
}

.dropdwn-input .relative.inline-flex.w-full {
  width: 100px;
}

.dropdwn-input .relative.inline-flex.w-full .btn-dropdown {
  border-right: 0;
}

.dropdwn-input .absolute.top-full.enter-done {
  width: 100%;
}

.dropdwn-input-email .relative.inline-flex.w-full {
  width: 350px;
}

.dropdwn-input-email .relative.inline-flex.w-full .btn-dropdown {
  border-left: 0;
}

/* Input end*/

/* Dollar input Start*/
.sign-icon-input {
  margin-bottom: 0px;
}

.sign-icon-input .dollar-input {
  position: relative;
  color: #181818;
}

.sign-icon-input input {
  padding-left: 30px;
  font-size: 18px;
}

.sign-icon-input .dollar-input::after {
  /* content: "%"; */
  content: "$";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-22%);
  font-size: 18px;
  height: 100%;
  color: #181818;
  /* padding-left: 15px; */
  padding-left: 10px;

}

.settings-section .sign-icon-input .dollar-input::after {
  left: 10px;
}

.settings-section .sign-icon-input input {
  padding-left: 70px;
}

.sign-icon-input .dollar-input::after {
  transform: translateY(-26%);
}

.settings-section .sign-icon-input .sign-sec {
  position: relative;
}

.settings-section .sign-icon-input .sign-sec::before {
  position: absolute;
  height: 100%;
  width: 1px;
  content: "";
  left: 60px;
  background: #181818;
}

/* Dollar input End*/


/* % input Start*/
.sign-icon-percentage-input {
  margin-bottom: 0px;
}

.sign-icon-percentage-input .percentage-input {
  position: relative;
  color: #181818;
}

.sign-icon-percentage-input input {
  padding-right: 30px;
  font-size: 18px;
}

.sign-icon-percentage-input .percentage-input::after {
  content: "%";
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-22%);
  font-size: 18px;
  height: 100%;
  color: #181818;
  padding-left: 10px;
}

.settings-section .sign-icon-percentage-input .percentage-input::after {
  left: 10px;
}

.settings-section .sign-icon-percentage-input input {
  padding-left: 70px;
}

.sign-icon-percentage-input .percentage-input::after {
  transform: translateY(-26%);
}

.settings-section .sign-icon-percentage-input .sign-sec {
  position: relative;
}

.settings-section .sign-icon-percentage-input .sign-sec::before {
  position: absolute;
  height: 100%;
  width: 1px;
  content: "";
  left: 60px;
  background: #181818;
}

/* % input End*/

.annual-member-fee .border-section-main {
  border-top: 0;
  border-bottom-width: 1px;
}

.individual-membership-fee .border-section-main {
  border-bottom-width: 1px;
}

.membership-input-width {
  max-width: 40%;
}

/*btn Start*/
.btn {
  padding: 11px 36px;
  transition: 0.5s;
  height: 52px;
}

.btn-pink,
.tox .tox-button {
  background: #AA3361 !important;
  background-color: #AA3361 !important;
  transition: 0.5s;
  color: #fff;
  cursor: pointer;
  border: none !important;
  border-radius: 0 !important;
}

.btn-pink.cursor-not-allowed {
  background: #b92262a6 !important;
  background-color: #b92262a6 !important;
}

.btn-pink:hover,
.tox .tox-button:hover {
  background: #901b4c !important;
  background-color: #901b4c !important;
  transition: 0.5s;
  color: #fff;
}

.btn-pink.cursor-not-allowed:hover {
  background: #901b4c !important;
  background-color: #901b4c !important;
}

.btn-pink-border {
  background: transparent !important;
  background-color: transparent !important;
  border: 2px solid #AA3361 !important;
  color: #AA3361 !important;
  transition: 0.5s !important;
  cursor: pointer;
}

.btn-pink-border:hover {
  border: 2px solid #901b4c !important;
  transition: 0.5s !important;
  color: #901b4c !important;
}

.btn-disabled-border {
  color: #fff !important;
  /* border: 2px solid #cbcbcb !important; */
  background: #a8a8a8 !important;
  transition: 0.5s !important;
  cursor: pointer;
}

.border-b-color {
  border-bottom: 1px Solid #AA3361;
}

.btn-gray,
.tox .tox-button--secondary {
  background: #181818 !important;
  background-color: #181818 !important;
  transition: 0.5s !important;
  color: #fff !important;
  cursor: pointer !important;
  border-radius: 0 !important;
}

.btn-gray:hover,
.tox .tox-button--secondary:hover {
  background: #646464 !important;
  background-color: #646464 !important;
  transition: 0.5s;
  color: #ffff !important;
}

.btn-lighblue {
  background: #AA3361 !important;
  background-color: #AA3361 !important;
  transition: 0.5s;
  color: #fff;
  cursor: pointer;
}

.btn-lighblue:hover {
  transition: 0.5s;
  background: #648194 !important;
  background-color: #648194 !important;
  color: #fff;
}

.btn-lighblue-border {
  background: transparent !important;
  background-color: transparent !important;
  border: 2px solid #AA3361 !important;
  color: #AA3361 !important;
  transition: 0.5s;
  cursor: pointer;
}

.btn-lighblue-border:hover {
  border: 2px solid #587181 !important;
  color: #587181 !important;
  transition: 0.5s;
}

.btn-green {
  transition: 0.5s;
  background: #56a351 !important;
  background-color: #56a351 !important;
  color: #fff;
  cursor: pointer;
}

.btn-green:hover {
  transition: 0.5s;
  background: #468842 !important;
  background-color: #468842 !important;
  color: #fff;
}

.btn:hover {
  transition: 0.5s;
}

.add-edite-f-name .btn-dropdown,
.individual-f-name .btn-dropdown {
  border-right: 0;
}

.add-edit-profile-email .btn-dropdown,
.individual-email .btn-dropdown {
  border-left: 0;
}

/*btn End*/

.margin-left-59 {
  margin-left: -59px;
}

.table-title-txt {
  font-size: 22px;
  padding-left: 20px;
}

/* Checkbox Start*/

.form-checkbox:checked {
  background-color: transparent !important;
}

.form-checkbox:checked {
  background-size: 75% !important;
}

.form-checkbox,
.form-radio {
  border-color: #181818 !important;
}

.custom-checkbox-select .css-e56m7-control,
.custom-checkbox-select .css-1rjh3ff-control,
.custom-time-picker {
  min-height: 55px;
  border: 1px solid #181818;
}

.css-b62m3t-container {
  overflow: unset !important;
}

.business-precence-accordian .css-qbdosj-Input {
  height: 0 !important;
  max-height: 56px;
}

/* Checkbox End*/

/* Checkbox Custom Start */

.form-checkbox-new {
  border-radius: 0;
  height: 25px !important;
  width: 25px !important;
}

/* Custom Table start */

.table-bg {
  background: #AA3361;
  background-color: #AA3361;
}

.custom-tbody tr:nth-child(even) {
  background: #c8c8c8;
}

.custom-action-btn {
  background: linear-gradient(0deg,
      rgba(199, 199, 199, 1) 0%,
      rgba(255, 255, 255, 1) 100%);
}

/* Custom Table End */

/* Table Scolling css */
.fixed_header {
  width: 100%;
  /* table-layout: fixed; */
  /* border-collapse: collapse; */
  background: #fff;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: 360px;
}

/* .fixed_header thead tr {
 display: block;
 width: 100%;
} */

.fixed_header thead {
  background: #AA3361;
  background-color: #AA3361;
}

.fixed_header th,
.fixed_header td {
  padding: 20px;
  text-align: center;
}

.project-tbl .custom-tbl-td td:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.partner-tbl .custom-tbl-td td:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.stretegic-member-tbl .custom-tbl-td td:first-child {
  text-align: left;
}

.border-section-main {
  border-bottom: 1px solid #e5e5e5;
  /* margin-bottom: 30px; */
}

.border-section-main:nth-last-child(2) {
  /* border: none; */
}

/* Table Scolling css */

.tbl-th {
  padding: 17px 0;
}

.tble-td-txt {
  font-size: 18px;
}

.tble-td-txt {
  font-size: 20px;
  color: #231f20;
  padding: 17px 0;
}

/* Custom-table-history Start */
.custom-tbl-td-history:nth-child(odd) {
  background: #f0f0f0;
}

.custom-tbl-td-history:nth-child(even) {
  background: #fff !important;
}

/* Custom-table-history End */

.custom-tbl-td td {
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  text-align: left;
}

.custom-tbl-td td {
  font-size: 16px;
  font-family: 'Roboto', sans-serif !important;
}

table .tble-th-txt {
  font-size: 18px;
  font-family: 'Roboto', sans-serif !important;
}

.custom-hr {
  width: 95%;
  margin: 0 auto;
  border-color: #181818;
}

/* Check Box Start */

/* Checkbox Custom Start */
.custom-checkbox-set input[type="checkbox"],
input[type="checkbox"].check-box-custom {
  cursor: pointer;
}

.custom-checkbox-set input[type="checkbox"],
input[type="checkbox"].check-box-custom {
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: transparent !important;
  margin: 0 !important;
  width: 25px !important;
  height: 25px !important;
  /* border: 1px solid #181818 !important; */
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(117 117 117 / var(--tw-ring-opacity));
  display: grid !important;
  place-content: center !important;
  border-radius: 0 !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #181818);
}

.custom-checkbox-set input[type="checkbox"]::before,
input[type="checkbox"].check-box-custom:before {
  content: "" !important;
  width: 15px !important;
  height: 15px !important;
  background-image: url(../public/checked-icon.svg) !important;
  background-size: contain !important;
  transform: scale(0) !important;
  transform-origin: center !important;
  transition: 120ms transform ease-in-out !important;
  box-shadow: inset 1em 1em var(--form-control-color) !important;
  background-color: transparent !important;
}

.custom-checkbox-set input[type="checkbox"]:focus,
input[type="checkbox"].check-box-custom:focus {
  border: 0px solid transparent !important;
  outline: 0px solid transparent !important;
  outline-offset: 0 !important;
}


.custom-checkbox-set input[type="checkbox"]:checked::before,
input[type="checkbox"].check-box-custom:checked::before {
  transform: scale(1.2) !important;
}

.custom-checkbox-set input[type="checkbox"],
input[type="checkbox"].check-box-custom {
  outline: none !important;
  background-color: transparent !important;
  background-image: none !important;
  appearance: none !important;
  border: none !important;
}

.custom-checkbox-set input[type="checkbox"]:hover,
input[type="checkbox"].check-box-customHover {
  background-color: unset !important;
}

.disabled-checkbox input[type="checkbox"] {
  cursor: not-allowed !important;
  background-color: #dfdfdf !important;
  appearance: none !important;
  border: none !important;

}

.disabled-checkbox input[type="checkbox"]:focus {
  cursor: not-allowed !important;
  background-color: #dfdfdf !important;
  appearance: none !important;
}

.disabled-checkbox input[type="checkbox"]:hover {
  cursor: not-allowed !important;
  background-color: #dfdfdf !important;
  appearance: none !important;
}

.disabled-checkbox input[type='checkbox']:checked {
  background-image: none !important;
}


/* Check Box Start */

/* Date Picker Start */

.date-pickers {
  position: relative;
  padding-right: 19px !important;
}

.date-pickers:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0.8px;
  background: #181818;
  right: 50px;
  top: 0;
}

.date-pickers::before {
  content: "";
  position: absolute;
  height: 27px;
  width: 27px;
  background: url(../public/calender.svg) !important;
  background-repeat: no-repeat !important;
  right: 11px;
  top: 53%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.date-pickers:focus.date-pickers:after {
  background: #AA3361;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent !important;
}

::-webkit-calendar-picker-indicator {
  background: url(../public/calender.svg) !important;
  background-position: center !important;
  background-size: contain !important;
  opacity: 0 !important;
}

.date-pickers.time-pikers::before {
  display: none !important;
}

/* Dare Picker End*/

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: unset !important;
  outline-offset: unset !important;
  --tw-ring-inset: unset !important;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: unset !important;
  --tw-ring-offset-shadow: unset !important;
  --tw-ring-shadow: unset !important;
  box-shadow: unset !important;
  border-color: #AA3361 !important;
}


/* Modal Satrt*/

#add-edit-modal .header-title {
  font-size: 41px;
}

#add-edit-modal {
  width: 85%;
  margin: 10px auto;
}



/* Modal End*/

.padding-r-0 .inputs-lists {
  padding-right: 0;
}

.tbl-th-styling label {
  display: flex;
  align-items: flex-end;
}

/* Membership Task List Input Start */
.dollar-sign {
  position: relative;
  display: inline-block;
}

.pricing-table-sec input {
  width: 100%;
}

.dollar-sign:before {
  content: "$";
  position: absolute;
  top: 51%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #181818;
  padding-left: 10px;
}

.percentage-sign {
  position: relative;
  display: inline-block;
}

.percentage-sign:after {
  content: "%";
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #181818;
  padding-left: 10px;
}

.percentage-sign.with-border-line:after {
  right: 22px;
  font-size: 18px;
}

.percentage-sign.with-border-line:before {
  position: absolute;
  content: "";
  height: 100%;
  right: 55px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  background: #181818;
}

/* Membership Task List Input End */

.tbl-detail-list input.disabled-input-table {
  background: #f2f2f2;
  border: none;
}

.delete-text-line-after,
.delete-text-line-before {
  position: relative;
}

.delete-text-line-after::after,
.delete-text-line-before::before {
  position: absolute;
  content: "";
  background: #181818;
  height: 1px;
  max-height: 1px;
  width: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* common input style start */
.input-sec-texts:nth-child(odd) {
  padding-right: 45px;
}

.input-sec-texts:nth-child(even) {
  padding-left: 45px;
}

.date-pickers {
  color: #181818;
}



/* common input style end */
/* .custom-checkbox-select{
  border: 1.5px solid #181818 !important;
} */

/* .upcomming-events-globle-tab .css-b62m3t-container{
  z-index:unset !important;
} */

.custom-checkbox-select .css-b62m3t-container {
  z-index: unset !important;
}

.custom-checkbox-select .css-1okebmr-indicatorSeparator .css-tlfecz-indicatorContainer {
  background-color: #181818 !important;
}

.custom-checkbox-select .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.custom-checkbox-select .css-1xc3v61-indicatorContainer {
  color: #181818 !important;
}

.css-qbdosj-Input {
  height: auto !important;
}

.breadcome-sec ul>li>a:hover {
  color: white !important;
}

.breadcome-sec ul li a svg.breadcrumb-menu-svg:hover {
  color: white !important;
}

.css-nne2zq-menu {
  z-index: 99999 !important;
}

/* view entry page style start*/
.progress-steps .tab-sec-p {
  position: relative;
  width: 20%;
}

.progress-steps .tab-sec-p:after {
  border-top: 2px dashed #b3b4b5;
  content: "";
  left: 98%;
  position: absolute;
  top: 25px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform: translatex(-50%);
  transform: translatex(-50%);
  width: 100%;
  z-index: 0;
}

.progress-steps .tab-sec-p:last-child:after {
  border-top: 0px dashed red;
}

.entry-steps-section {
  border-radius: 100%;
  display: flex;
  justify-content: center;
}

.entry-steps-section a {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 1;
}

.entry-steps-section a .round-count {
  align-items: center;
  border-radius: 100%;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
}

.activetab .tab-title {
  color: #AA3361;
}

.tab-title {
  color: #181818;
  margin-top: 20px;
}



/* view entry page style end */

.tab input.toggle-main[type="checkbox"]+label::before {
  content: "+";
  font-weight: bold;
  border-radius: 9999px;
}

[type='checkbox'],
[type='radio'] {
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type='checkbox'] {
  border-radius: 0px;
}

.tab input.toggle-main[type="checkbox"]:checked+label::before {
  color: #AA3361;
  content: "-";
}

.tab label::before {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  font-size: 1.25rem;
  text-align: center;
  transition: 0.5s ease-out;
  color: #AA3361;
}

[type='checkbox'] {
  border-radius: 0px;
}

[type='checkbox']:checked,
[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.tab input:checked~.tab-content.committee-info-overflow {
  overflow: unset !important;
}

.tab input:checked~.tab-content {
  max-height: 100%;
  transition: max-height 0.5s ease-in;
}


.tab-content {
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

/* create a corporatemember page style start */
.finance-body-sec .payment-reconciliation {
  display: flex;
  justify-content: space-between;
}

.css-1fdsijx-ValueContainer {
  overflow: unset !important;
}

/* batch job extraction page syle start */
.fixed_header thead {
  background: #AA3361 !important;
  background-color: #AA3361 !important;
}

.batch-job-extraction-table tbody td,
.batch-job-extraction-table thead th,
.fixed_header.setup-approval-process-table tbody td,
.fixed_header.setup-approval-process-table thead th {
  text-align: left;
}

/* batch job extraction page syle end */

/* payment reconcilation page syle start */
.outstanding-invoice-detail-modal #outstandingInvoices {
  width: 60%;
  margin: 0 auto;
}

.payment-view-approve-modal #paymentviewapprove {
  width: 60%;
  margin: 0 auto;
}

.outstanding-invoice-detail-modal .table-title-txt {
  padding-left: 0;
}

.outstanding-invoice-detail-modal textarea {
  height: auto;
}

/* payment reconcilation page syle end */

/* create a voucher code style start */
.prasantage-input {
  position: relative;
}

.prasantage-input:after {
  border-left: 1px solid #64748b;
  color: #757575;
  content: "%";
  font-size: 20px;
  height: 99%;
  padding: 15px 15px 15px 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.disabled-input .css-e56m7-control,
.disabled-input .custom-checkbox-select,
.disabled-input input,
.disabled-input textarea {
  background: #e9e9ea;
  cursor: not-allowed;
}

.dollor-sign:after {
  border-right: 1px solid #64748b;
  color: #757575;
  content: "$";
  font-size: 20px;
  height: 99%;
  left: 10px;
  padding: 15px 20px 15px 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dollor-sign {
  position: relative;
}

.select-all-membership-model .fa-angle-left:before {
  content: "<" !important;
  font-family: monospace;
  font-weight: bold;
}

.select-all-membership-model .fa-angle-right:before {
  content: ">" !important;
  font-family: monospace;
  font-weight: bold;
}

.select-all-membership-model #select-All-Members {
  width: 90%;
  margin: 0 auto;
}

.select-all-member-popup #select-All-Members .table-listing {
  height: 492px;
  overflow: hidden;
  overflow-y: auto;
}

/* create a voucher code style end */

/* common input margin-bottom */

label.filter-lable.text-xl.block.font-medium.\32 .xl\:mb-1.lg\:\[0\.25rem\].theme-color.min-h-30 {
  margin-bottom: 0.5rem !important;
  font-weight: 500;
}

h2.text-xl.theme-color.mb-2.font-bold {
  font-weight: 500 !important;
}

label.filter-lable.block.font-medium.\32 .xl\:mb-1.lg\:\[0\.25rem\].theme-color.min-h-30 {
  margin-bottom: 0.5rem !important;
  font-weight: 500;
}

label.filter-lable.block.text-xl.font-medium.mb-1.theme-color.min-h-30 {
  margin-bottom: 0.5rem !important;
  font-weight: 500;
}

.css-1jqq78o-placeholder {
  color: #181818 !important;
}

.custom-card2 .post-details label {
  font-size: 14px !important;
}


/* create entry page testsite  */
.custom-checkbox-select .css-1okebmr-indicatorSeparator {
  display: none;
}


.custom-checkbox-selectv .css-tlfecz-indicatorContainer {
  color: #181818;
}

/* .css-e56m7-control, .css-e56m7-control {
  border: 1px solid #181818 !important;
  height: auto !important;
  min-height: 55px !important;
  max-height: auto !important;
  color: #181818 !important;
} */


/* create entry page testsite  

/* event registration approve page style start */
#cancel-booking-modal {
  width: 50%;
  margin: 0 auto;
}

.event-registration-popup .table-title-txt {
  font-size: 22px;
}

/* event registration approve page style end */


/* payment-reconcilation-model design set start */
.payment-reconcilation-model .custom-tbl-td td {
  text-align: center !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  height: 55px;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* CreateIndividualMembers page style start */
.btn-pink-custom {
  min-width: 250px;
  background-color: #AA3361;
}

.guest-event-booking-main .custom-accordian input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.guest-event-booking-main .custom-accordian .tab-label {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
}

.guest-event-booking-main .custom-accordian .tab-content {
  opacity: 0;
  padding: 0 1em;
  transition: all .35s;
  color: #181818;
}

.guest-event-booking-main .custom-accordian input:checked~.tab-content {
  opacity: 1;
  position: relative;
}

.guest-event-booking-main .custom-accordian input:checked+.tab-label:after {
  content: "-";
}

.guest-event-booking-main .custom-accordian .tab-label:after {
  content: "+";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
  left: 0;
  position: absolute;
}


/* CreateIndividualMembers page style end */
.conferance-data-showing .tab-content {
  max-height: unset !important;
}

/* entry-topic-set-z-index */
.entry-type-index-set .css-b62m3t-container {
  z-index: 9 !important;
  overflow: unset !important;
}

.entry-topic-set-index .css-b62m3t-container {
  z-index: 3;
}

/* entry-topic-set-z-index */

/* some model design style set */
#send-for-approve-modal {
  width: 65%;
  margin: 0 auto;
}

#send-for-approve-modal {
  width: 50%;
  margin: 0 auto;
}

#send-payment-link-modal {
  width: 35%;
  margin: 0 auto;
}

.pricing-disabled-input {
  background: #e9e9ea !important;
  cursor: not-allowed !important;
}

.price-custom-selector {
  width: 320px;
  height: 56px !important;
}

.css-1fdsijx-ValueContainer {
  width: 320px !important;
}

.price-custom-selector .css-1u9des2-indicatorSeparator {
  width: 0 !important;
  background-color: #fff !important;
}

.price-custom-selector .css-1okebmr-indicatorSeparator {
  width: 0 !important;
}

.price-custom-selector .css-e56m7-control,
.css-1rjh3ff-control {
  height: 53px !important;
}

.price-custom-selector .css-8mmkcg {
  color: #181818 !important;
}

/* Responsive -Styles */
@media only screen and (max-width: 1980px) {
  .margin-left-12-res {
    margin-left: -13px;
  }
}

@media only screen and (max-width:1750px) {

  .sign-icon-percentage-input .percentage-input::after {
    left: 80px;
  }
}

@media only screen and (max-width: 1650px) {
  .custom-card {
    padding: 30px 30px 24px;
  }

  #send-payment-link-modal {
    width: 50%;
    margin: 0 auto;
  }

  #send-for-approve-modal {
    width: 85%;
    margin: 0 auto;
  }

  #send-for-approve-modal {
    width: 85%;
    margin: 0 auto;
  }

  .btn {
    padding: 11px 20px !important;
    line-height: 22px !important;
  }

  .sign-icon-percentage-input .percentage-input::after {
    left: 98px;
  }

  .main-body-section {
    padding: 25px 35px;
  }

  input,
  .btn-dropdown,
  select,
  textarea {
    height: 52px;
  }
}

@media only screen and (max-width: 1536px) {
  .res-membershipCampain {
    display: flex !important;
    margin-bottom: 100px !important;
  }

  #send-payment-link-modal {
    width: 70%;
    margin: 0 auto;
  }

  .sign-icon-percentage-input .percentage-input::after {
    left: 67px;
  }

  .res-checkbox-left {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 1393px) {
  .res-membershipCampain {
    display: flex !important;
    margin-bottom: 150px !important;
    align-items: flex-end !important;
  }

  .sign-icon-percentage-input .percentage-input::after {
    left: 56px;
  }

  .res-checkbox-left {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 1367px) {

  .footer-section {
    padding: 0 82px;
  }

  #cancel-booking-modal {
    width: 65%;
    margin: 0 auto;
  }

  .sign-icon-percentage-input .percentage-input::after {
    left: 62px;
  }
}

@media only screen and (max-width: 1366px) {
  .main-body-section {
    padding: 25px;
  }

}

@media only screen and (max-width:1280px) {
  .sign-icon-percentage-input .percentage-input::after {
    left: 50px;
  }
}

@media only screen and (max-width: 1235px) {
  .main-body-section {
    padding: 15px;
  }

  #cancel-booking-modal {
    width: 100%;
    margin: 0 auto;
  }


}

@media only screen and (max-width: 1200px) {

  input,
  .btn-dropdown,
  select,
  textarea {
    height: 45px;
  }

  .membership-input-width {
    max-width: 60%;
  }



}

@media only screen and (max-width: 1153px) {
  .gap-res {
    gap: 0.5rem !important;
  }

}

@media only screen and (max-width: 1121px) {
  .gap-res {
    display: flex !important;
  }


  .sign-icon-percentage-input .percentage-input::after {
    left: 148px;
  }
}

@media only screen and (max-width: 1060px) {
  .res-membershipCampain {
    display: flex !important;
    margin-bottom: 180px !important;
  }

  .res-checkbox-left {
    padding-left: 0 !important;
  }

}

@media only screen and (max-width: 1024px) {
  #add-edit-modal {
    width: 100%;
  }


  .select-all-membership-model #select-All-Members {
    width: 100%;
  }

  .payment-view-approve-modal #paymentviewapprove {
    width: 100%;
    margin: 0 auto;
  }

  .outstanding-invoice-detail-modal #outstandingInvoices {
    width: 100%;
    margin: 0 auto;
  }

  .sign-icon-percentage-input .percentage-input::after {
    left: 58px;
  }

  .input-sec-texts:nth-child(odd) {
    padding-right: 0;
  }

  .input-sec-texts:nth-child(even) {
    padding-right: 0;
  }
}

@media only screen and (max-width:991px) {
  .sign-icon-percentage-input .percentage-input::after {
    left: 180px;
  }


}

@media only screen and (max-width:820px) {
  .sign-icon-percentage-input .percentage-input::after {
    left: 148px;
  }
}

@media only screen and (max-width:767px) {
  .sign-icon-percentage-input .percentage-input::after {
    left: 149px;
  }

  #send-for-approve-modal {
    width: 100%;
    margin: 0 auto;
  }

  #send-for-approve-modal {
    width: 100%;
    margin: 0 auto;
  }

  #send-payment-link-modal {
    width: 100%;
    margin: 0 auto;
  }

  .table-title-txt {
    padding-left: 0px;
  }
}

@media only screen and (max-width:540px) {
  .sign-icon-percentage-input .percentage-input::after {
    left: 35px;
  }

  .event-registration-popup .table-title-txt {
    font-size: 18px;
    line-height: 20px;
  }
}